export default function Modal(params = {content, closed, opened, size, isVideo, video}){
    this._opened = params.opened;
    this.modal = document.createElement('div');
    this.modal.className = 'modal';
    
    if (params.size == undefined){
        this.size = '';
    } else {
        this.size = `modal-${params.size}`;
    }

    if (params.isVideo == undefined){
        this.isVideo = false;
    } else {
        this.isVideo = params.isVideo;
    }

    if (params.video == undefined){
        this.video = false;
    } else {
        this.video = params.video;
    }

    this.modalContainer = document.createElement('div');
    this.modalContainer.className = "modal-container modal-dialog-centered";
    
    this.block = document.createElement('div');
    this.block.className = 'modal-block container';

    if (this.isVideo) {
        this.block.className += ' modal-video';
    }

    
    if (typeof this.size == 'string' && this.size != ''){
        this.block.className += ` ${this.size}`;
    }

    //debugger;
    if (this.isVideo && this.video) {
        this.block.innerHTML = '';
    } else {
        if (params.content.innerHTML){
            this.block.append(params.content);
        } else {
            this.block.innerHTML = params.content;
        }
    }


    this.backdrop = document.createElement('div');
    this.backdrop.className = 'modal-backdrop';
    
    this.closeBtn = document.createElement('a');
    this.closeBtn.innerHTML = 'Закрыть';
    this.closeBtn.className = 'modal-close';
    this.closeBtn.href = '#';
    this.block.append(this.closeBtn);
    
    this.modal.append(this.modalContainer);
    this.modalContainer.append(this.block);
    this.modalContainer.append(this.backdrop);

    document.body.append(this.modal);

    this.closeBtn.addEventListener('click', e => {
        e.preventDefault();
        
        this.close();
        params.closed();
    });

    this.backdrop.addEventListener('click', e => {
        e.preventDefault();
        
        this.close();
        params.closed();
    })
}

Modal.prototype.show = show;
Modal.prototype.close = close;

function show (){
    this.modal.style.display = 'block';  
    document.body.classList.add('modal-open');

    document.querySelectorAll('video:not(.lazy)').forEach((videoElement) => videoElement.pause());

    if (this.isVideo && this.video){
        const iframe = document.createElement('iframe');
        iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
        iframe.setAttribute('allowfullscreen', 'allowfullscreen');
        iframe.setAttribute('frameborder', '0');
        iframe.src = this.video;
        this.block.append(iframe);
    }
    
    setTimeout(() => {
        this.modal.style.opacity = 1;
        this.modal.style.top = 0;
    }, 1)
    
    if (typeof this._opened == 'function'){
        this._opened();
    }
}

function close () {
    document.querySelectorAll('video:not(.lazy)').forEach((videoElement) => videoElement.play());

    //this.backdrop.remove();
    this.modal.style.opacity = 0;
    this.modal.style.top = '';

    if (this.isVideo && this.video){
        this.block.innerHTML = '';
    }
    
    setTimeout(() => {
        document.body.classList.remove('modal-open');
        this.modal.style.display = '';
    }, 250);
    
}