"use strict";
import City from './City';

export default (async function(){
    let cities = [];
    let citiesBtns;
    let cityAddressElement;
    let cityPhoneElement;
    let currentCityId = 1;
    
    document.addEventListener('DOMContentLoaded', async () => {
        citiesBtns = document.querySelectorAll('.contactsCity');
        cityAddressElement = document.querySelector('.contactsInfo-address');
        cityPhoneElement = document.querySelector('.contactsInfo-phone');
        
        //const json = await load();
        citiesBtns.forEach(btn => {
            const id = btn.getAttribute('data-city');
            const address = btn.getAttribute('data-address');
            const phone = btn.getAttribute('data-phone');
            
            cities.push(
                new City({
                    id,
                    address,
                    phone
                })
            );
        });

        update();

        citiesBtns.forEach(btn => {
            btn.addEventListener('click', e => {
                e.preventDefault();

                

                currentCityId = parseInt(e.currentTarget.getAttribute('data-city'));

                update();
            });
        });
    });

    function update(){
        if (typeof currentCityId == 'number' && isFinite(currentCityId)){
            cityAddressElement.innerHTML = '';
            cityAddressElement.append(
                cities.find(city => city.id == currentCityId).address
            );

            cityPhoneElement.innerHTML = '';
            cityPhoneElement.append(
                cities.find(city => city.id == currentCityId).phone
            );
        }
    }

    async function load(){
        const response = await fetch('./js/cities.json');
        const json = await response.json();

        return json;
    }
})();