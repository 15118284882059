"use strict";
export default class City {
    id;
    #address;
    #phone;

    constructor({id, address, phone}){
        this.id = id;
        this.#address = address;
        this.#phone = phone;
    }

    get address() {
        const elem = document.createElement('div');
        elem.innerHTML = this.#address;
        return elem;
    }

    get phone() {
        const elem = document.createElement('a');
        elem.href = `tel:${this.#phone}`;
        elem.textContent = this.#phone;
        return elem;
    }
}