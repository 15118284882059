export default (function(){
    function canUseWebP() {
        var elem = document.createElement('canvas');
        if (!!(elem.getContext && elem.getContext('2d'))) {
            return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
        }
        return false;
    }

    document.addEventListener('DOMContentLoaded', () => {

    });

    document.addEventListener("DOMContentLoaded", function() {
        var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
      
        if ("IntersectionObserver" in window) {
          let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
              if (entry.isIntersecting) {
                let lazyImage = entry.target;
                lazyImage.src = lazyImage.dataset.src;
                lazyImage.srcset = lazyImage.dataset.srcset;
                lazyImage.classList.remove("lazy");
                lazyImageObserver.unobserve(lazyImage);
              }
            });
          });
      
          lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
          });
        } else {
          // Possibly fall back to a more compatible method here
        }
    });

    document.addEventListener("DOMContentLoaded", () => {
        document.querySelectorAll('video.lazy').forEach(video => {
            videoLazy(video);

            window.addEventListener('scroll', () => {
                videoLazy(video);
            });
        });
    });

    function videoLazy(el){
        const coords = getCoords(el);
        if (el.classList.contains('lazy') && window.scrollY + window.innerHeight >= coords.top + 100){
            el.setAttribute('autoplay', 'autoplay');
            el.classList.remove('lazy');
            el.play();
        }
    }

    function getCoords(elem) {
        var box = elem.getBoundingClientRect();
      
        return {
          top: box.top + pageYOffset,
          left: box.left + pageXOffset
        };
    };
})();