"use strict";
import Swiper from 'swiper';
import accordionCss from 'accordion/src/accordion.css';
import Accordion from 'accordion/src/accordion';
import Modal from './Modal';

export default (function(){
    let sliderContainer;
    let slider;
    let sliderIndex = 0;
    let sliderOffset = 0;

    document.addEventListener('DOMContentLoaded', () => {
        const sliderNextButton = document.querySelector('.reviewsSlider-next');
        const sliderPrevButton = document.querySelector('.reviewsSlider-prev');
        sliderContainer = document.querySelector('.reviewsSlider');
    
        slider = new Swiper(sliderContainer, {
            navigation: {
                nextEl: sliderNextButton,
                prevEl: sliderPrevButton,
            },
            on: {
                slideChangeTransitionStart: () => {
                    sliderIndex = slider.realIndex;
    
                    if (sliderIndex + 1 >= slider.slides.length){
                        sliderNextButton.classList.add('swiper-button-disabled');
                    } else {
                        sliderNextButton.classList.remove('swiper-button-disabled');
                    }
    
                    updateSlider();
                },
            },
            
            slidesPerView: 2,
            spaceBetween: 10,
            updateOnImagesReady: true,
            breakpoints: {
                992: {
                  spaceBetween: 20,
                  slidesPerView: 3,
                }
            }
        });

        slider.on('imagesReady', () => {
            updateSlider();
        });

        for(const el of document.querySelectorAll(".accordion"))
            new Accordion(el, {
                modal: true
            });
    });

    function updateSlider(){
        document.querySelector('.reviewsSlider-panel .slider-current')
            .textContent = (sliderIndex < 9) ? `0${sliderIndex + 1}` : sliderIndex + 1;


        document.querySelector('.reviewsSlider-panel .slider-count')
            .textContent = (slider.slides.length < 9) ? `0${slider.slides.length}` : slider.slides.length;
    }

    document.addEventListener('DOMContentLoaded', () => {
        const reviews = document.querySelectorAll('.reviews-full[data-review]');

        reviews.forEach(review => {
            const id = review.getAttribute('data-review');
            const btns = document.querySelectorAll(`.reviewsSlide-image[data-review="${id}"]`);
            
            const modal = new Modal({
                content: review,
                closed: function(){},
                opened: function(){}
            });
            
            btns.forEach(btn => {
                btn.addEventListener('click', e => {
                    e.preventDefault();
                    modal.show();
                });
            })
        });
    });
})();