"use strict";

export default (function(){
    let menuButton;
    let menuClose;
    let menu;
    let menuInner;
    let menuIsShown = false;
    let menuAnimation;
    let menuWidth;
    let menuHeight;

    document.addEventListener('DOMContentLoaded', function(){
        menuButton  = document.getElementById('menu-btn');
        menu        = document.getElementById('menu');
        menuInner   = document.querySelector('.menu-inner');
        menuClose   = document.querySelector('.menu-close');
        window.menuInner = menuInner;

        menuButton.addEventListener('click', e => {
            e.preventDefault();

            toggle();
        });

        window.addEventListener('resize', () => {
            resize();
        });

        menuClose.addEventListener('click', e => {
            e.preventDefault();

            toggle();
        })
    });

    function toggle (){
        menuIsShown = !menuIsShown;

        update();
    }

    function resize(){
        update();
    }

    function update(){
        if (window.innerWidth >= 768){
            menuWidth = 630;
        } else {
            menuWidth = window.innerWidth - 40;
        }

        menuHeight = menuInner.offsetHeight;
        //menu.style.maxHeight = window.innerHeight;

        if (menuIsShown) {
            menuShow();
        } else {
            menuHide();
        }
    }

    function getScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    function menuShow(){
        clearTimeout(menuAnimation);

        document.body.style.paddingRight = `${getScrollbarWidth()}px`;
        menuButton.style.marginRight = `${getScrollbarWidth()}px`;
        menu.style.marginRight = `${getScrollbarWidth()}px`;

        menuButton.querySelector('.menuButton-l3').style.opacity = 0;
        menuButton.querySelector('.menuButton-l1').style.width = `${menuWidth}px`;
        menuButton.querySelector('.menuButton-l2').style.width = `${menuWidth}px`;

        document.body.classList.add('menu-show');
        menu.style.zIndex = 20;

        menuAnimation = setTimeout(() => {
            menuButton.querySelector('.menuButton-l2').style.height = 0;
            menuButton.querySelector('.menuButton-l2').style.top = `${menuHeight + 10}px`;
            menu.style.height = `${menuHeight}px`;
            menuButton.classList.add('open');
        }, 250);
    }

    function menuHide(){
        clearTimeout(menuAnimation);

        menuButton.querySelector('.menuButton-l2').style.top = null;
        menuButton.querySelector('.menuButton-l2').style.height = null;
        menu.style.height = null;

        menuAnimation = setTimeout(()=>{
            menuButton.style.marginRight = null;
            menu.style.marginRight = null;
            menuButton.querySelector('.menuButton-l3').style.opacity = null;
            menuButton.querySelector('.menuButton-l3').style.marginRight = null;
            menuButton.querySelector('.menuButton-l1').style.width = null;
            menuButton.querySelector('.menuButton-l1').style.marginRight = null;
            menuButton.querySelector('.menuButton-l2').style.width = null;
            menuButton.querySelector('.menuButton-l2').style.marginRight = null;
            menuButton.classList.remove('open');
            document.body.style.paddingRight = null;
            document.body.classList.remove('menu-show');
            menu.style.zIndex = null;

        }, 250);
    }

    document.querySelectorAll('.menu-inner li > a').forEach(menuItem => {
        menuItem.addEventListener('click', e => {
            e.preventDefault();
            toggle();
            document.querySelector(e.target.getAttribute('href')).scrollIntoView({behavior: "smooth"});
            //this.update();
        });
    });

    document.querySelectorAll('.js-anchor').forEach(menuItem => {
        menuItem.addEventListener('click', e => {
            e.preventDefault();

            //this.isOpen = false;
            document.querySelector(e.target.getAttribute('href')).scrollIntoView({behavior: "smooth"});
            //this.update();
        });
    });


    document.addEventListener('click', (e) => {
      const target = e.target;
      const itsMenu = target == menu || menu.contains(target);
      const itsMenuButton = target == menuButton || menuButton.contains(target);
      if (!itsMenu && !itsMenuButton && menuIsShown) {
        toggle();
      }
    });
})();
