"use strict";
import Swiper from 'swiper';
import Modal from './Modal';

let sliderOneIndex = 0;
let sliderTwoIndex = 0;
let sliderThreeIndex = 0;
let sliderFourIndex = 0;
let portfolioTitleElement;
let portfolioTextElement;
let portfolioLinkElement;
let sliderTwoNextButton;
let sliderTwoPrevButton;
let sliderFourNextButton;
let sliderFourPrevButton;
let slider1;
let slider2;
let slider3;
let slider4;

export default (function(){

    document.addEventListener('DOMContentLoaded', function(){
        const sliderOneNextButton = document.querySelector('.portfolioSlider1-next');
        const sliderOnePrevButton = document.querySelector('.portfolioSlider1-prev');

        sliderTwoNextButton = document.querySelector('.portfolioSlider2-next');
        sliderTwoPrevButton = document.querySelector('.portfolioSlider2-prev');

        sliderFourNextButton = document.querySelector('.portfolioSlider4-next');
        sliderFourPrevButton = document.querySelector('.portfolioSlider4-prev');

        const sliderThreeNextButton = document.querySelector('.portfolioSlider3-next');
        const sliderThreePrevButton = document.querySelector('.portfolioSlider3-prev');

        portfolioTitleElement = document.querySelector('.portfolioText-title');
        portfolioTextElement = document.querySelector('.portfolioText-text');
        portfolioLinkElement = document.querySelector('.portfolioText-link');

        slider1 = new Swiper('.portfolioSlider1-container', {
            pagination: {
                el: '.portfolioSlider1-pagination'
            },
            navigation: {
                nextEl: sliderOneNextButton,
                prevEl: sliderOnePrevButton,
            },
            on: {
                slideChangeTransitionStart: () => {
                  if (slider1) {
                    sliderOneIndex = slider1.realIndex;

                    // if (sliderOneIndex + 1 >= slider1.slides.length){
                    //   sliderOneNextButton.classList.add('swiper-button-disabled');
                    // } else {
                    //   sliderOneNextButton.classList.remove('swiper-button-disabled');
                    // }

                    updateSliderOne();
                  }

                },
            },
            slidesPerView: 'auto',
            spaceBetween: 10,
            roundLengths: true,
            slidesOffsetAfter: 10000,
            updateOnImagesReady: true,
          speed: 750,
          loop: true,
          autoplay: {
              delay: 3000,
            },
            reakpoints: {
                768: {
                  spaceBetween: 20
                }
            }
        });

        initTwo();

        initFour();

        updateSliderOne();
        
        slider3 = new Swiper('.portfolioSliderThree-container', {
            pagination: {
                el: '.portfolioSlider3-pagination'
            },
            navigation: {
                nextEl: sliderThreeNextButton,
                prevEl: sliderThreePrevButton,
            },
            on: {
                slideChangeTransitionStart: () => {
                  if (slider3) {
                    sliderThreeIndex = slider3.realIndex;

                    // if (sliderThreeIndex + 1 >= slider3.slides.length){
                    //   sliderThreeNextButton.classList.add('swiper-button-disabled');
                    // } else {
                    //   sliderThreeNextButton.classList.remove('swiper-button-disabled');
                    // }

                    updateSliderThree();
                  }

                },
            },
            slidesPerView: 'auto',
            spaceBetween: 10,
            roundLengths: true,
            slidesOffsetAfter: 10000,
            updateOnImagesReady: true,
          speed: 750,
          loop: true,
          autoplay: {
            delay: 3000,
          },
            reakpoints: {
                768: {
                  spaceBetween: 20
                }
            }
        });

        updateSliderThree();
    });

    function updateSliderOne(){
        const title = document.querySelector(`[data-slide-index='${sliderOneIndex}'] .portfolioSlide-title`).textContent;
        const text = document.querySelector(`[data-slide-index='${sliderOneIndex}'] .portfolioSlide-text`).textContent;
        const link = document.querySelector(`[data-slide-index='${sliderOneIndex}'] .portfolioSlide-link`).getAttribute('href');

        portfolioTitleElement.textContent = title;
        portfolioTextElement.textContent = text;
        portfolioLinkElement.setAttribute('href', link);

        document.querySelector('.portfolioSliderOne-panel .slider-current')
            .textContent = (sliderOneIndex < 9) ? `0${sliderOneIndex + 1}` : sliderOneIndex + 1;
        document.querySelector('.portfolioSliderOne-panel .slider-count')
            .textContent = (slider1.loopedSlides < 9) ? `0${slider1.loopedSlides}` : slider1.loopedSlides;
    }

    function updateSliderTwo(){
        const current = document.querySelector('.portfolioSliderTwo-panel .slider-current');
        const count = document.querySelector('.portfolioSliderTwo-panel .slider-count');

        if (current){
            current.textContent = (sliderTwoIndex < 9) ? `0${sliderTwoIndex + 1}` : sliderTwoIndex + 1;
        }
        
        if (count) {
            count.textContent = (slider2.loopedSlides < 9) ? `0${slider2.loopedSlides}` : slider2.loopedSlides;
        }
    }
    
    function updateSliderThree(){
        const current = document.querySelector('.portfolioSliderThree-panel .slider-current');
        const count = document.querySelector('.portfolioSliderThree-panel .slider-count');

        if (current){
            current.textContent = (sliderThreeIndex < 9) ? `0${sliderThreeIndex + 1}` : sliderThreeIndex + 1;
        }

        if (count){
            count.textContent = (slider3.loopedSlides < 9) ? `0${slider3.loopedSlides}` : slider3.loopedSlides;
        }
    }

    function updateSliderFour(){
        const current = document.querySelector('.portfolioSliderFour-panel .slider-current');
        const count = document.querySelector('.portfolioSliderFour-panel .slider-count');
        
        if (current){
            current.textContent = (sliderFourIndex < 9) ? `0${sliderFourIndex + 1}` : sliderFourIndex + 1;
        }

        if (count){
            count.textContent = (slider4.loopedSlides < 9) ? `0${slider4.loopedSlides}` : slider4.loopedSlides;
        }
    }

    function resizeTwo(){
        const container = document.querySelector('.portfolioSliderTwo-container');

        if (container){
            if (window.innerWidth < 992) {
                container.removeAttribute('dir');
    
                if (slider2){
                    slider2.destroy();
                    createTwo();
                }
                if (container.getAttribute('dir')){
                }
            } else {
                container.setAttribute('dir', 'rtl');
    
                if (slider2){
                    slider2.destroy();
                    createTwo();
                }
                if (!container.getAttribute('dir')) {
                }
            }
        }
    }

    function resizeFour(){
        const container = document.querySelector('.portfolioSliderFour-container');

        if (container){
            if (window.innerWidth < 992) {
                container.removeAttribute('dir');
    
                if (slider4){
                    slider4.destroy();
                    createFour();
                }
                if (container.getAttribute('dir')){
                }
            } else {
                container.setAttribute('dir', 'rtl');
    
                if (slider4){
                    slider4.destroy();
                    createFour();
                }
                if (!container.getAttribute('dir')) {
                }
            }
        }
    }

    function initTwo(){
        resizeTwo();

        createTwo();
    }

    function initFour(){
        resizeFour();

        createFour();
    }

    function createTwo(){
        slider2 = new Swiper('.portfolioSliderTwo-container', {
            pagination: {
                el: '.portfolioSlider2-pagination'
            },
            navigation: {
                nextEl: sliderTwoNextButton,
                prevEl: sliderTwoPrevButton,
            },
            on: {
                slideChangeTransitionStart: () => {
                  if (slider2) {
                    sliderTwoIndex = slider2.realIndex;

                    // if (sliderTwoIndex + 1 >= slider2.slides.length){
                    //   sliderTwoNextButton.classList.add('swiper-button-disabled');
                    // } else {
                    //   sliderTwoNextButton.classList.remove('swiper-button-disabled');
                    // }

                    updateSliderTwo();
                  }
                },
                resize: () => {
                    resizeTwo();
                },
            },
            slidesPerView: 'auto',
            spaceBetween: 10,
            roundLengths: true,
            updateOnImagesReady: true,
            slidesOffsetAfter: 10000,
          speed: 750,
          loop: true,
          autoplay: {
            delay: 3000,
          },
            reakpoints: {
                768: {
                  spaceBetween: 20
                }
            }
        });

        if ('destroyed' in slider2 && slider2.destroyed) return;
                
        updateSliderTwo();
    }

    function createFour(){
        slider4 = new Swiper('.portfolioSliderFour-container', {
            pagination: {
                el: '.portfolioSlider4-pagination'
            },
            navigation: {
                nextEl: sliderFourNextButton,
                prevEl: sliderFourPrevButton,
            },
            on: {
                slideChangeTransitionStart: () => {
                  if (slider4) {
                    sliderFourIndex = slider4.realIndex;

                    // if (sliderFourIndex + 1 >= slider4.slides.length){
                    //   sliderFourNextButton.classList.add('swiper-button-disabled');
                    // } else {
                    //   sliderFourNextButton.classList.remove('swiper-button-disabled');
                    // }

                    updateSliderFour();
                  }
                },
                resize: () => {
                    resizeFour();
                },
            },
            slidesPerView: 'auto',
            spaceBetween: 10,
            roundLengths: true,
            updateOnImagesReady: true,
            slidesOffsetAfter: 10000,
            speed: 750,
            loop: true,
            autoplay: {
              delay: 3000,
            },
            reakpoints: {
                768: {
                  spaceBetween: 20
                }
            }
        });

        if ('destroyed' in slider4 && slider4.destroyed) return;
                
        updateSliderFour();
    }
})();