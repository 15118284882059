import './osdetect';
import './menu';
import './portfolio';
import './form';
import './reviews';
import './contacts';
import Modal from './Modal';
import Lazy from './Lazy';
// document.addEventListener('DOMContentLoaded', function(){
//     let video = document.querySelector('.header-video video');
    
//     const can = {
//         hevc: video.canPlayType('video/mp4; codecs="hvc1"'),
//         webm: video.canPlayType('video/webm'),
//         h264: video.canPlayType('video/mp4; codecs="avc1"'),
//         ogg: video.canPlayType('video/ogg'),
//     };
// });

document.addEventListener('DOMContentLoaded', () => {
    const content = document.querySelector('#feedbackModal');
    window.feedbackModal = new Modal({
        content,
        closed: function(){},
        opened: function(){}
    });

    document.querySelectorAll('.feedbackModal-show').forEach(btn => {
        btn.addEventListener('click', e => {
            e.preventDefault();
          window.feedbackModal.show();
        });
    });
});

document.addEventListener('DOMContentLoaded', () => {
    const portfolioVideos = document.querySelectorAll('[data-video="true"][data-video-url]');

    portfolioVideos.forEach(videoEl => {
        const url = videoEl.getAttribute('data-video-url');
        
        const modal = new Modal({
            content: document.createElement('div'),
            isVideo: true,
            video: url,
            closed: function(){},
            opened: function(){}
        });

        videoEl.addEventListener('click', e => {
            e.preventDefault();
            modal.show();
        });
    });
});