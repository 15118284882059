"use strict";
import Imask from 'imask';

export class FeedbackForm{
    #formEl;

    #nameIn;
    #phoneIn;
    #sendBtn;
    #doneEl;
    #url = '';
    
    name = '';
    phone = '';

    constructor({ form, name, phone, send, done }){
        this.#formEl = document.querySelector(form);
        this.#nameIn = document.querySelector(name);
        this.#phoneIn = document.querySelector(phone);
        this.#sendBtn = document.querySelector(send);

        this.#doneEl = document.querySelector(done);
        
        this.#url = this.#formEl.getAttribute('action');

        this.#nameIn.addEventListener('input', e => {
            if (typeof e.target.value == 'string' && e.target.value != ''){
                this.name = e.target.value;
            } else {
                this.name = '';
            }

            e.target.setAttribute('required', '');
            e.target.classList.remove('invalid');
        });

        this.#phoneIn.addEventListener('input', e => {
            if (typeof e.target.value == 'string' && e.target.value != ''){
                this.phone = e.target.value;
            } else {
                this.phone = '';
            }

            e.target.setAttribute('required', '');
            e.target.classList.remove('invalid');
        });

        this.#sendBtn.addEventListener('click', async e => {
            e.preventDefault();

            this.#nameIn.setAttribute('required', 'required');
            this.#nameIn.setAttribute('disabled', 'disabled');

            this.#phoneIn.setAttribute('required', 'required');
            this.#phoneIn.setAttribute('disabled', 'disabled');
            this.#sendBtn.setAttribute('disabled', 'disabled');
            
            const status = await this.send();

            this.#nameIn.removeAttribute('disabled');
            this.#phoneIn.removeAttribute('disabled');
            this.#sendBtn.removeAttribute('disabled');

            if (!status) {
                this.#nameIn.classList.add('invalid');
                this.#phoneIn.classList.add('invalid');
            }
        });
    }
    
    async send(){
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('phone', this.phone);

        let status = false;

        try{
            const response = await fetch(this.#url, {
                method: 'POST',
                body: formData,
                cache: 'no-cache'
            });
    
            const json = await response.json();
            status = json.status;
        } catch(err){
            status = false;
        }

        if (status) {
            this.#doneEl.classList.add('send');
            if (window.feedbackModal) {
              setTimeout(() => {
                window.feedbackModal.close();
              }, 3000);
            }
        }

        return status;
    }
}

export default (function(){
    document.addEventListener('DOMContentLoaded', () => {
        const phoneinputs = document.querySelectorAll('input[type="tel"]');

        phoneinputs.forEach(input => {
            IMask(input, {
                mask: '+{7}(000)000 00 000'
            });
        });

        const formOne = new FeedbackForm({
            form: '#feedbackOne-form',
            name: '#feedbackOne-name',
            phone: '#feedbackOne-phone',
            send: '#feedbackOne-submit',
            done: '#feedbackOne-done'
        });

        const formTwo = new FeedbackForm({
            form: '#feedbackTwo-form',
            name: '#feedbackTwo-name',
            phone: '#feedbackTwo-phone',
            send: '#feedbackTwo-submit',
            done: '#feedbackTwo-done'
        });

        const formModal = new FeedbackForm({
            form: '#feedbackModal-form',
            name: '#feedbackModal-name',
            phone: '#feedbackModal-phone',
            send: '#feedbackModal-submit',
            done: '#feedbackModal-done'
        });
    });
})();